import React from "react"

export const WorkIndex = ({ index, fontScale = 1 }) => (
	<div
		style={{
			//fontFamily: "Arial",
			fontFamily: 'nasalization',
			WebkitTextStroke: '1px white',

			fontSize: 50 * fontScale,
			color: "black",
			//textShadow: "0 0 3px white",
			lineHeight: "1em",
			display: "inline-block",
			//letterSpacing: '0.1em',
			borderBottom: "1px solid white",
			paddingBottom: 4,
			//marginTop: vBandWidth,
			marginBottom: 10,
		}}
		children={("0" + (index + 1)).slice(-2)}
	/>
);

export const Title = ({ children }) => (
	<div
		className="title"
		children={children}
	/>
)

export const Subtitle = ({ children }) => (
	<div
		className="subtitle"
		children={children}
	/>
)
