import { Grid } from "@material-ui/core"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import TweenMax, { Power2 } from "gsap"
import React, { useEffect, useState } from "react"
import { useSpring } from "react-spring"
import "swiper/dist/css/swiper.min.css"
import Swiper from "swiper/dist/js/swiper.esm.bundle"
import SEO from "../components/seo"
import { Subtitle, Title, WorkIndex } from "../components/typography"
import arrow from "../images/ic_arrow.svg"
import plus from "../images/plus.svg"



let swiper

const Controllers = props => {
  const { prev, next } = props

  return (
    <div className="controllers-container">
      <Grid container>
        <Grid item xs={12} className="controller-arrow-container">
          <img
            src={arrow}
            className="controller-arrow next"
            onClick={next}
            alt="social-icon"
          />
        </Grid>
        <Grid item xs={12} className="controller-arrow-container">
          <img
            src={arrow}
            className="controller-arrow prev"
            onClick={prev}
            alt="social-icon"
          />
        </Grid>
      </Grid>
    </div>
  )
}

const Progress = props => {
  const { index, progress, counter } = props

  const selected = index % counter

  return (
    <div className="progress-container">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <p className="progress-number">01</p>
        </Grid>
        <Grid item className="progress-bar">
          <div
            className="progress-current"
            style={{
              height: `${148 / counter}px`,
              transform: `translateY(${progress * (counter - 1) * 100}%)`,
            }}
          />
        </Grid>
        <Grid item>
          <p className="progress-number">{("0" + counter).slice(-2)}</p>
        </Grid>
      </Grid>
    </div>
  )
}

const trans1 = (x, y, m = 0.1) => `translate3d(${x * m}px,${(y * m) / 2}px,0)`

const ContentSlide = ({ content, index }) => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 100, friction: 140 },
  }))

  console.log(93292929, content)

  return (
    <Grid
      onMouseMove={({ clientX: x, clientY: y, target }) => {
        var rect = target.getBoundingClientRect()
        set({ xy: [x - rect.left - rect.width / 2, y - window.innerHeight] })
      }}
      onMouseOut={() => set({ xy: [0, 0] })}
      key={index}
      container
      direction="column"
      className="swiper-slide"
    >
      <Link to={`/works/${content.slug}`}>
        <div className="slider-in-anim">
          <div className="filtered-content">
            <WorkIndex index={index} />
            <Title children={content.title} />
            <Subtitle children={content.subtitle} />
            <p className="slider-link">
              view case study
              <img className="plus" src={plus} />
            </p>
            <Img
              style={{ width: "100%", height: "100%", position: "absolute" }}
              fluid={content.previewImage ? content.previewImage.fluid : ""}
            />
            {/* <img
              src={content.previewImage ? content.previewImage.file.url : ""}
            /> */}
          </div>
        </div>
      </Link>
    </Grid>
  )
}

const WorkPage = () => {
  const [index, setIndex] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    console.log("reb")

    const swiperContainer = new Swiper(".swiper-container", {
      loop: false,
      slidesPerView: "auto",
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      mousewheel: {
        invert: false,
      },
    })
    swiper = swiperContainer.length ? swiperContainer[0] : swiperContainer

    if (swiper) {
      swiper.on("slideChange", () => {
        setIndex(swiper.activeIndex)
      })
      swiper.on("transitionEnd", () => {
        console.log("aaaaaaaa", swiperContainer.progress)
        setProgress(swiperContainer.progress)
      })
    }

    let slides = document.querySelectorAll(".slider-in-anim")
    let slidesVisible = []

    slides.forEach((slide, index) => {
      let rect = slide.getBoundingClientRect()
      let inScreen = rect.left > 0 && rect.left < window.innerWidth

      if (!inScreen || slide.className.includes("swiper-slide-duplicate")) {
        slide.style.opacity = null
      } else {
        slidesVisible.push(slide)
      }
    })

    let duration = 0.6

    console.log(743829, duration, slides, slidesVisible)

    slidesVisible.forEach((slide, index) => {
      TweenMax.fromTo(
        slide,
        duration,
        {
          x: 200,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: Power2.easeOut,
          delay: duration + (slidesVisible.length - index) * duration * 0.3,
        }
      )
    })
  }, [])

  const prevSlide = () => {
    swiper.slidePrev()
  }

  const nextSlide = () => {
    swiper.slideNext()
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulWork(sort: { fields: order, order: ASC }) {
            nodes {
              id
              title
              subtitle
              slug
              previewImage {
                id
                file {
                  url
                  fileName
                  contentType
                }
                fluid(maxWidth: 720, quality: 95) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      `}
      render={data => {
        console.log(87239, data, data.allContentfulWork.nodes.length, index)
        return (
          <div>
            <SEO />

            <Grid
              container
              direction="row"
              alignItems="stretch"
              wrap="nowrap"
              style={{ height: "100vh", position: "relative" }}
            >
              <div
                className="swiper-container"
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <div className="swiper-wrapper">
                  {data.allContentfulWork.nodes.map((element, index) => (
                    <ContentSlide key={index} content={element} index={index} />
                  ))}
                </div>
              </div>
            </Grid>
            <Controllers prev={prevSlide} next={nextSlide} />
            <Progress
              index={index}
              progress={progress}
              counter={data.allContentfulWork.nodes.length}
            />
          </div>
        )
      }}
    />
  )
}

export default WorkPage
